<template>
  <v-container fluid>
    <v-row class="pa-10" v-if="loading">
      <v-col cols="12">
        <loader></loader>
      </v-col>
    </v-row>
    <v-row
      v-else
      class="align-center"
      v-for="(notification, i) in notifications"
      v-bind:key="i"
      style="cursor: pointer"
      @click.stop="goToNotificationLink(notification)"
    >
      <v-col cols="2" class="d-flex justify-center">
        <v-avatar :color="goToNotificationColor(notification)">
          <v-icon dark>
            {{ getNotificationIcon(notification) }}
          </v-icon>
        </v-avatar>
      </v-col>
      <v-col cols="6">
        <div class="text-left pa-3 body-2 font-weight-bold">
          {{ notification.message }}
        </div>
      </v-col>
      <v-col
        cols="4"
        class="text-right d-flex flex-column align-center justify-end"
      >
        <div v-if="markAsReadButton">
          <div
            class="text-decoration-underline primary--text"
            :style="{ cursor: 'pointer' }"
            v-if="!notification.pivot.seen_at"
            @click="markAsRead(notification.id)"
          >
            <span>Mark as read</span>
          </div>
        </div>

        <span class="caption">
          {{ formatDate(notification.created_at, "DD.MM.YYYY HH:mm") }}</span
        >
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row v-if="notifications.length === 0">
      <v-card flat class="text-center title px-8 py-6">
        All caught up - no new notifications
      </v-card>
    </v-row>
    <v-row v-if="loadMoreButton">
      <v-btn
        color="primary"
        block
        :disabled="notifications.length === total"
        @click.native.stop="loadMoreNotifications"
      >
        Load More
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import date from "@/mixins/date";
export default {
  name: "NotificationLIst",
  mixins: [date],
  components: {
    Loader: () => import("@/components/core/Loader")
  },
  data() {
    return {
      item: {}
    };
  },
  props: {
    unseen: {
      type: Boolean,
      default: false
    },
    actionName: {
      type: String,
      default: "getItems"
    },
    getterName: {
      type: String,
      default: "items"
    },
    loadingName: {
      type: String,
      default: "loading"
    },
    markAsReadButton: {
      type: Boolean,
      default: false
    },
    loadMoreButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters["notifications/" + this.loadingName];
      }
    },
    notifications: {
      get() {
        return this.$store.getters["notifications/" + this.getterName];
      }
    },
    total: {
      get() {
        return this.$store.getters["notifications/total"];
      }
    },
    pagination: {
      get() {
        return this.$store.getters["notifications/pagination"];
      }
    },
    user() {
      return JSON.parse(this.$auth.remember()) || {};
    }
  },
  async mounted() {
    await this.getNotifications();
  },
  watch: {
    pagination: {
      handler() {
        this.getNotifications();
      },
      deep: true
    }
  },
  methods: {
    async getNotifications() {
      await this.$store.dispatch("notifications/" + this.actionName, {
        id: this.user.id,
        unseen: this.unseen,
        pagination: this.pagination
      });
    },
    getNotificationClass(notification) {
      switch (notification.notifiable_type) {
        case "App\\Models\\WorkforceRequest":
          return "workforce";
        case "App\\Models\\User":
          return "user";
        case "App\\Models\\Employee":
          return "employee";
        default:
          return "employee";
      }
    },
    goToNotificationLink(notification) {
      this.$router.push({
        name: "show-" + this.getNotificationClass(notification),
        params: { id: notification.notifiable_id }
      });
    },
    goToNotificationColor(notification) {
      let notificationClass = this.getNotificationClass(notification);
      switch (notificationClass) {
        case "workforce":
          return "pink accent-4";
        case "user":
          return "primary";
        case "employee":
          return "orange darken-3";
        default:
          return "primary";
      }
    },
    getNotificationIcon(notification) {
      let notificationClass = this.getNotificationClass(notification);
      switch (notificationClass) {
        case "workforce":
          return "mdi-lan";
        case "user":
          return "mdi-account-circle";
        case "employee":
          return "mdi-account-hard-hat";
        default:
          return "mdi-lan";
      }
    },
    async markAsRead(id) {
      await this.$store
        .dispatch("notifications/markAsRead", {
          id: id,
          user_id: this.user.id
        })
        .then(() => this.getNotifications());
    },
    async loadMoreNotifications() {
      await this.$store.dispatch("notifications/setPagination", {
        perPage: this.pagination.perPage + this.pagination.loadRows
      });
    }
  }
};
</script>
