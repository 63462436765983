<template>
  <v-menu
    activator="#notificationsMenu"
    transition="slide-y-transition"
    offset-y
    nudge-left="140px"
    rounded
    max-width="400px"
    max-height="400px"
    min-width="320px"
  >
    <v-card>
      <v-card-subtitle class="d-flex justify-space-between pa-3">
        <v-row no-gutters justify="start">
          <v-col cols="4" class="text-left">Notifications</v-col>
          <v-col cols="4" class="text-center" style="cursor: pointer"
            ><div
              class="text-decoration-underline primary--text"
              @click.stop="markAllAsRead()"
            >
              <span>Mark all as read</span>
            </div></v-col
          >
          <v-col cols="4" class="text-right">
            <router-link
              class="text-decoration-underline primary--text"
              :to="{ name: 'notification' }"
            >
              <span>See all</span>
            </router-link></v-col
          >
        </v-row>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <notification-list
          :unseen="true"
          :mark-as-read-button="true"
          getter-name="newNotifications"
          loading-name="loadingNew"
          action-name="getNewNotifications"
        />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import NotificationList from "@/components/notifications/NotificationList";
export default {
  name: "NotificationsMenu",
  components: {
    NotificationList
  },
  computed: {
    ...mapGetters({
      newNotifications: "notifications/newNotifications"
    }),
    user() {
      return JSON.parse(this.$auth.remember()) || {};
    }
  },
  methods: {
    async getNotifications() {
      await this.$store.dispatch("notifications/getNewNotifications", {
        id: this.user.id,
        unseen: true,
        pagination: this.pagination
      });
    },
    async markAllAsRead() {
      let ids = _.map(this.newNotifications, "id");
      console.log(ids);
      await this.$store
        .dispatch("notifications/markAllAsRead", {
          notifications: ids,
          user_id: this.user.id
        })
        .then(() => this.getNotifications());
    }
  }
};
</script>
